import { useLocation } from '@reach/router'
import React, { useContext, useState } from 'react'
import { FC, useEffect } from "react"
import Helmet from 'react-helmet'
import { useEnvironment } from '../environment/Environment'
import { useLocalStorage } from '../environment/useLocalStorage'

export const AnalyticsWrapper: FC = ({ children }) => (
  <>
    <GOptimise />
    <AdParameters/>
    <AnalyticsProvider>
      <>
        <HeapAnalytics />
        {children}
      </>
    </AnalyticsProvider>
  </>
)

export const GOptimise: FC = () => {
  const { GOOGLE_OPTIMIZE_ID } = useEnvironment()

  useEffect(() => {
    if (window && window.dataLayer) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'optimize.activate' })
    }
  }, [])

  return (
    <Helmet>
      <script async src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_ID}`}></script>
    </Helmet>
  )
}

export const AdParameters: FC = () => {
  const location = useLocation()
  const [, setGclid] = useLocalStorage<string>('gclid', '')
  const [, setFbclid] = useLocalStorage<string>('fbclid', '')
  const [, setCampaign] = useLocalStorage<string>('campaign', '')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const gclid = params.get('gclid')
    const fbclid = params.get('fbclid')
    const campaign = params.get('utm_campaign')
    if(gclid) {
      setGclid(gclid)
    }
    if (fbclid) {
      setFbclid(fbclid)
    }
    if(campaign) {
      setCampaign(campaign)
    }
  }, [location])

  return null
}

type AnalyticsContext = {
  split?: string
  registerGoogleEvent: (eventName: string, data?: any) => void
  registerFacebookEvent: (eventName: string, data?: any) => void
  registerFacebookCustomEvent: (eventName: string, data?: any) => void
  registerHeapEvent: (eventName: string, data?: any) => void
  assignUserId: (id: string) => void
  assignEmailAddress: (emailAddress: string) => void
}

const noop = () => {}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  split: undefined,
  registerFacebookEvent: noop,
  registerFacebookCustomEvent: noop,
  registerGoogleEvent: noop,
  registerHeapEvent: noop,
  assignUserId: noop,
  assignEmailAddress: noop
})

export const useAnalytics = () => useContext(AnalyticsContext)


const AnalyticsProvider: FC = ({ ...props }) => {
  const { OPTIMISE_EXPERIMENT_ID } = useEnvironment()

  const split = useGoogleOptimize(OPTIMISE_EXPERIMENT_ID)

  const registerGoogleEvent = (eventName: string, data?: any) => {
    // console.log('>>REGISTER GOOGLE EVENT', eventName, data)
    if (window && window.gtag) {
      window.gtag(`event`, eventName, data)
    }
  }

  const registerFacebookEvent = (eventName: string, data?: any) => {
    // console.log('>>REGISTER FACEBOOK EVENT', eventName, data)
    if (window && window.fbq) {
      window.fbq(`track`, eventName, data)
    }
  }

  const registerFacebookCustomEvent = (eventName: string, data?: any) => {
    // console.log('>>REGISTER FACEBOOK CUSTOM EVENT', eventName, data)
    if (window && window.fbq) {
      window.fbq(`trackCustom`, eventName, data)
    }
  }

  const registerHeapEvent = (eventName: string, data?: any) => {
    // console.log('>>REGISTER HEAP EVENT', eventName, data)
    if(window.heap && window.heap.track) {
      window.heap.track(eventName, data)
    }
  }

  const assignEmailAddress = (emailAddress: string) => {
    // console.log('>>ASSIGN EMAIL', emailAddress)
    if(window.heap && window.heap.addUserProperties) {
      window.heap.addUserProperties({"email": emailAddress})
    }
  }

  const assignUserId = (id: string) => {
    // console.log('>>ASSIGN ID', id)
    if(window.heap && window.heap.identify) {
      window.heap.identify(id)
    }
  }

  const context: AnalyticsContext = {
    split,
    registerGoogleEvent,
    registerFacebookEvent,
    registerFacebookCustomEvent,
    registerHeapEvent,
    assignUserId,
    assignEmailAddress
  }

  return (
    <AnalyticsContext.Provider value={context} {...props} />
  )
}

export const HeapAnalytics: FC = () => {
  const { split } = useAnalytics()

  useEffect(() => {
    if (split && window.heap && window.heap.addUserProperties) {
      window.heap.addUserProperties({ split })
    }
  }, [split])

  return null
}


const useGoogleOptimize = (experimentId: string) => {
  const [variant, setVariant] = useState<string>('0')

  const updateVariant = (value: string | undefined) => {
    // console.log('>>VARIANT UPDATE', value)
    setVariant(value === undefined || value === null ? '0' : value)
  }

  const delayedInitialization = () => {
    const { google_optimize } = window

    const value = google_optimize && google_optimize.get(experimentId)

    // console.log('>>>VARIANT VALUE', value)
    updateVariant(value)
  }

  useEffect(() => {
    const { dataLayer } = window

    if (dataLayer) {
      const hideEnd = dataLayer && dataLayer.hide && dataLayer.hide.end

      if (hideEnd) {
        dataLayer.hide.end = () => {
          delayedInitialization()
          hideEnd()
        }
      } else {
        delayedInitialization()
      }

      dataLayer.push('event', 'optimize.callback', {
        name: experimentId,
        callback: updateVariant,
      })
    }

    return () => {
      if (dataLayer) {
        dataLayer.push('event', 'optimize.callback', {
          name: experimentId,
          callback: updateVariant,
          remove: true,
        })
      }
    }
  }, [experimentId])

  return variant
}
