import React, { FC } from 'react'
import { Link } from '@reach/router'
import tw from 'twin.macro'

import { ROUTES } from './Navigation'

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-gray-300`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const FooterLink = tw(Link)`border-b-2 text-gray-400 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300`;


export const Footer: FC<{ version: string }> = ({ version }) => {
  const year = new Date().getUTCFullYear()

  return (
    <div tw="py-12 bg-gray-800 text-gray-400">
      <div tw="max-w-screen-xl mx-auto py-0 px-4 sm:px-6 lg:py-8 lg:px-8">
        <div tw="flex flex-wrap justify-between">
          <WideColumn>
            <div tw="flex items-center justify-center md:justify-start">
              <h5 tw="text-xl font-black font-logo text-gray-300">lawhive</h5>
            </div>
            <p tw="mt-4 text-xs max-w-xs mx-auto md:mx-0">
              LawHive is not a law firm and does not provide legal advice. LawHive uses a network of licensed solicitors to provide legal work directly to clients. Please read our <Link to={ROUTES.legal.termsOfService}>Terms</Link> for more information.
            </p>
          </WideColumn>
          <Column>
            <ColumnHeading>Navigation</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <FooterLink to={ROUTES.home.benefits}>Benefits</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink to={ROUTES.home.howItWorks}>How it works</FooterLink>
              </LinkListItem>
              {/* <LinkListItem>
                <FooterLink to={ROUTES.home.services}>Services</FooterLink>
              </LinkListItem> */}
              <LinkListItem>
                <FooterLink to={ROUTES.home.testimonials}>Testimonials</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink to={ROUTES.knowledgeHub}>Knowledge Hub</FooterLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <FooterLink to={ROUTES.legal.privacyPolicy}>Privacy Policy</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink to={ROUTES.legal.termsOfService}>Terms</FooterLink>
              </LinkListItem>
            </LinkList>
          </Column>
        </div>

        <div tw="mt-8 max-w-xs font-medium text-xs">
          &copy; {year} LawHive<br />
          103c Camley Street, London N1C 4PF
        </div>

        <p tw="mt-4 text-xs leading-6">
          v{version}
        </p>
      </div>
    </div>
  )
}
