import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import tw, { GlobalStyles } from 'twin.macro'
import { graphql, StaticQuery } from 'gatsby'
import { IntlProvider } from 'react-intl'

// @ts-ignore
import config from '../../config/site'

import { EnvironmentContext } from '../environment/Environment'
import { Footer } from './Footer'
import { SEO } from '../seo/SEO'
import { AnalyticsWrapper } from '../analytics/AnalyticsWrapper'
import { Link } from '@reach/router'


export const ButtonStyle = "w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white text-center bg-pink-500 hover:bg-pink-400 focus:(outline-none border-pink-700) transition duration-150 ease-in-out md:(py-4 text-lg px-10)"

export const Button = tw.button`${ButtonStyle}`
export const ButtonLink = tw(Link)`${ButtonStyle}`

// export const H2 = tw.h2`text-2xl font-semibold`
export const H2 = tw.h2`text-2xl leading-9 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-10`

export const PageSection = tw.section`py-12 lg:py-16 bg-white`
export const ContentContainer = tw.div`max-w-screen-xl mx-auto px-6 sm:px-6 lg:px-8`

const metadataQuery = graphql`
  query SiteMetadataLookup {
    site {
      siteMetadata {
        environment {
          BRANCH
          CONVERSION_CAMPAIGN_MAIN_CTA
          CONVERSION_CAMPAIGN_SECONDARY_CTA
          CONVERSION_CAMPAIGN_FORM
          STRIPE_PUBLIC_KEY
          GOOGLE_OPTIMIZE_ID
          OPTIMISE_EXPERIMENT_ID
          TRACKING_IDS
          FRESHCHAT_TOKEN
          FRESHCHAT_HOST
          APP_ROOT_URL
          APP_CREATE_CASE_ROOT_URL
        }
        version
      }
    }
  }
`

export const Layout: FC = ({ children }) => {

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>{config.defaultSiteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={config.siteDescription} />
        <meta property="og:title" content={config.defaultSiteTitle} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={config.defaultSiteTitle} />
        <link rel="stylesheet preload" href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=PT+Serif:wght@700&display=swap" />
        <style>
        {`
          .async-hide { opacity: 0 !important} </style>
        `}
        </style>
      </Helmet>

      <SEO />

      <StaticQuery
        query={metadataQuery}
        render={data => (
          <EnvironmentContext.Provider value={data.site.siteMetadata.environment}>
            <AnalyticsWrapper>
              <IntlProvider locale={'en-US'}>
                <ThemeProvider theme={{}}>
                  <div tw="font-sans antialiased">
                    {children}
                    <Footer version={data.site.siteMetadata.version} />
                  </div>
                </ThemeProvider>
              </IntlProvider>
            </AnalyticsWrapper>
          </EnvironmentContext.Provider>
        )}
      />
    </>
  )
}
