module.exports = {
  defaultSiteTitle: 'CancelledFlightRefund.com | Get your money back now',
  siteTitlePrefix: 'The fast, online way to use a licensed solicitor to help get your money back',
  publisher: 'LawHive', // Organization name used for SEO schema
  siteDescription: 'The fast, online way to use a licensed solicitor to help get your money back',
  siteUrl: 'https://cancelledflightrefund.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'LawHive', // Author for RSS author segment and SEO schema
  authorUrl: 'https://cancelledflightrefund.com', // URL used for author and publisher schema, can be a social profile or other personal site
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 630, // Change to the height of your default share image
  siteLogo: '', // Logo used for SEO, RSS, and App manifest
  copyright: 'Copyright © 2021 LawHive', // Copyright string for the RSS feed
}
