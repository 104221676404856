import { Link } from 'gatsby'
import React, { FC } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useAppLoginUrl } from '../environment/useAppUrl'
import { ContentContainer } from './Layout'
// import { CaseType } from '../../content/match-onboarding/OnboardingStep'

export const ROUTES = {
  home: {
    root: '/',
    benefits: '/#benefits',
    howItWorks: '/#how-it-works',
    services: '/#services',
    testimonials: '/#testimonials'
  },
  start: {
    case: '/start/case',
  },
  onboarding: {
    step1: `/onboarding/step1`,
    step2: '/onboarding/step2',
    step3: '/onboarding/step3',
    step4: '/onboarding/step4',
    claimSession: '/onboarding/claim-session',
    complete: '/onboarding/complete'
  },
  legal: {
    privacyPolicy: '/legal/privacy-policy/',
    termsOfService: '/legal/terms-of-service/'
  },
  knowledgeHub: '/knowledge-hub'
}

type NavigationItem = {
  label: string | JSX.Element
  to: string
}

const linkConfig: NavigationItem[] = [
  { label: 'How it works', to: ROUTES.home.howItWorks },
  { label: 'Benefits', to: ROUTES.home.benefits },
  // { label: 'Services', to: ROUTES.home.services },
  { label: 'Testimonials', to: ROUTES.home.testimonials },
  { label: 'Knowledge Hub', to: ROUTES.knowledgeHub }
]

const DesktopLink = styled(Link)<{ light?: boolean }>(({ light }) => [
  tw`font-medium text-gray-200 hover:text-white transition duration-150 ease-in-out mr-6 lg:mr-8 last:mr-0 border-b-2 pb-1 border-transparent hocus:border-white`,
  css`
    &.active {
      ${tw`text-pink-400 font-semibold`}
    }
  `,
  light && tw`text-gray-700 hover:text-gray-900 hocus:border-gray-900`
])

const DesktopLinkButton = styled(Link)<{ light?: boolean }>(({ light }) => [
  tw`hidden md:inline-block px-4 py-2 rounded-md text-gray-700 hover:text-gray-900 bg-white font-medium hover:bg-pink-100 transition duration-150 ease-in-out`,
  light && tw`bg-pink-500 text-pink-50 hover:(bg-pink-400 text-white)`
])

const Logo = styled.span<{ light?: boolean }>(({ light }) => [
  tw`font-logo text-xl sm:text-3xl`,
  light ? tw`text-black` : tw`text-white`
])

const MobileNavButton = styled.button<{ light?: boolean }>(({ light }) => [
  tw`inline-flex items-center justify-center p-2 rounded-md focus:outline-none transition duration-150 ease-in-out`,
  light ? tw`text-gray-900 hover:(text-black bg-pink-300) focus:(bg-pink-200 text-pink-500)` : tw`text-gray-100 hover:(bg-pink-200 text-white) focus:(bg-pink-200 text-pink-500)`
])

type NavigationProps = {
  light?: boolean
  onToggleMobile: () => void
}

export const DesktopNavigation: FC<NavigationProps>= ({ light, onToggleMobile }) => {
  const loginUrl = useAppLoginUrl()

  return (
    <ContentContainer>
      <nav tw="md:flex md:items-center md:justify-between">
        <div tw="flex justify-between items-center">
          {/* <a href="#" tw="inline-block py-2 text-white text-xl font-bold">Demopay</a> */}

          <Link to="/" aria-label="Home">
            <Logo light={light}>lawhive</Logo>
          </Link>

          <div tw="-mr-2 flex items-center md:hidden flex-no-wrap">
            <MobileNavButton light={light} onClick={onToggleMobile} type="button" id="main-menu" aria-label="Main menu" aria-haspopup="true">
              <svg tw="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h26M4 12h16M4 18h16" />
              </svg>
            </MobileNavButton>
          </div>

          {/* <div tw="inline-block cursor-pointer md:hidden">
            <div tw="bg-gray-400 w-8 mb-2" style={{height: '2px'}}></div>
            <div tw="bg-gray-400 w-8 mb-2" style={{height: '2px'}}></div>
            <div tw="bg-gray-400 w-8" style={{height: '2px'}}></div>
          </div> */}
        </div>

        <div>
          <div tw="hidden md:block">
            {linkConfig.map((l, i) => (
              <DesktopLink partiallyActive={true} key={`link-${i}`} to={l.to} activeClassName='active' light={light}>
                {l.label}
              </DesktopLink>
            ))}
            {/* <a
              href="#"
              tw="inline-block py-1 md:py-4 text-gray-100 mr-6 font-bold"
              >How it Works</a>
            <a
              href="#"
              tw="inline-block py-1 md:py-4 text-gray-400 hover:text-gray-100 mr-6"
              >Services</a>
            <a
              href="#"
              tw="inline-block py-1 md:py-4 text-gray-400 hover:text-gray-100"
              >Blog</a> */}
          </div>
        </div>

        <div tw="hidden md:block">
          {/* <DesktopLinkButton
            to={loginUrl}
            light={light}
          >
            Sign In
          </DesktopLinkButton> */}
        </div>
      </nav>




      {/* <nav tw="relative flex items-center justify-between sm:h-10 lg:justify-start">
        <div tw="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
          <div tw="flex items-center justify-between w-full md:w-auto">
            <Link to="/" aria-label="Home">
              <span tw="font-serif text-xl sm:text-3xl">lawhive</span>
            </Link>
            <div tw="-mr-2 flex items-center md:hidden flex-no-wrap">
              <button onClick={onToggleMobile} type="button" tw="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                <svg tw="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div tw="hidden ml-8 md:block lg:ml-10 md:pr-0">
          {linkConfig.map((l, i) => (
            <DesktopLink partiallyActive={true} key={`link-${i}`} to={l.to} activeClassName='active'>
              {l.label}
            </DesktopLink>
          ))}
        </div>
      </nav> */}
    </ContentContainer>
  )
}

const MobileLink = styled(Link)`
  ${tw`block mb-1 last:mb-0 px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out`}
  &.active {
    ${tw`text-pink-500`}
  }
`

export const MobileNavigation: FC<NavigationProps> = ({ onToggleMobile }) => {
  const loginUrl = useAppLoginUrl()

  return (
    <div tw="rounded-lg shadow-md">
      <div tw="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
        <div tw="px-5 pt-2 flex items-center justify-between">
          <div>
            <Link to="/" aria-label="Home">
              <span tw="font-logo">lawhive</span>
            </Link>
          </div>
          <div tw="-mr-2">
            <button onClick={onToggleMobile} type="button" tw="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
              <svg tw="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div tw="px-2 pt-2 pb-2">
          {linkConfig.map((l, i) => (
            <MobileLink key={`link-${i}`} to={l.to} activeClassName='active'>
              {l.label}
            </MobileLink>
          ))}
        </div>

        {/* <div tw="px-2 pt-2 pb-2 border-t-2 border-gray-100">
          <MobileLink to={loginUrl}>
            Sign In
          </MobileLink>
        </div> */}
      </div>
    </div>
  )
}

export const LoginButton = () => {
  const loginUrl = useAppLoginUrl()

  return (
    <Link to={loginUrl} tw="px-5 py-2 rounded-md bg-white text-pink-500 font-bold shadow-md">
      Log In
    </Link>
  )
}
