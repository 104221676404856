import * as React from 'react'
import { useContext } from 'react'

export interface Environment {
  BRANCH: string
  CONVERSION_CAMPAIGN_MAIN_CTA: string,
  CONVERSION_CAMPAIGN_SECONDARY_CTA: string,
  CONVERSION_CAMPAIGN_FORM: string,
  STRIPE_PUBLIC_KEY: string,
  AIRTABLE_CFR_URL: string,
  AIRTABLE_API_KEY: string,
  AIRTABLE_EVICTION_URL: string,
  GOOGLE_OPTIMIZE_ID: string,
  APP_ROOT_URL: string
  TRACKING_IDS: string,
  OPTIMISE_EXPERIMENT_ID: string,
  APP_CREATE_CASE_ROOT_URL: string
  FRESHCHAT_TOKEN: string
  FRESHCHAT_HOST: string
}

export const DEFAULT_ENVIRONMENT: Environment = {
  BRANCH: process.env.BRANCH || '',
  CONVERSION_CAMPAIGN_MAIN_CTA: process.env.CONVERSION_CAMPAIGN_MAIN_CTA || '',
  CONVERSION_CAMPAIGN_SECONDARY_CTA: process.env.CONVERSION_CAMPAIGN_SECONDARY_CTA || '',
  CONVERSION_CAMPAIGN_FORM: process.env.CONVERSION_CAMPAIGN_FORM || '',
  STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY || '',
  AIRTABLE_CFR_URL: process.env.AIRTABLE_CFR_URL || '',
  AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY || '',
  AIRTABLE_EVICTION_URL: process.env.AIRTABLE_EVICTION_URL || '',
  GOOGLE_OPTIMIZE_ID: process.env.GOOGLE_OPTIMIZE_ID || '',
  TRACKING_IDS: process.env.TRACKING_IDS || '',
  OPTIMISE_EXPERIMENT_ID: process.env.OPTIMISE_EXPERIMENT_ID || '',
  FRESHCHAT_TOKEN: process.env.FRESHCHAT_TOKEN || '',
  FRESHCHAT_HOST: process.env.FRESHCHAT_HOST || '',
  APP_ROOT_URL: '',
  APP_CREATE_CASE_ROOT_URL: ''
}

export const EnvironmentContext = React.createContext<Environment>(DEFAULT_ENVIRONMENT)

export const useEnvironment = (): Environment => useContext(EnvironmentContext)
