import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { Location } from '@reach/router'

// @ts-ignore
import config from '../../config/site'
import shareImage from '../../seo/share-image.png'

type SEOBaseProps = {
  pageUrl?: string,
  title?: string,
  description?: string,
  image?: string,
  imageWidth?: string,
  imageHeight?: string,
  type?: string,
  schemaOrgJSONLD?: object[]
  postNode?: any,
  postPath?: string,
  postSEO?: boolean
}

export const SEO: FC<SEOBaseProps> = ({ pageUrl, title, description, image, imageWidth, imageHeight, type, schemaOrgJSONLD, postNode, postPath, postSEO }) => {
  // Set Default OpenGraph Parameters for Fallback
  pageUrl = pageUrl || config.siteUrl
  title = title ? `${title} | ${config.siteTitlePrefix}` : config.defaultSiteTitle
  description = description || config.siteDescription
  image = config.siteUrl + (image || shareImage)
  imageWidth = imageWidth || config.shareImageWidth
  imageHeight = imageHeight || config.shareImageHeight

  // Default Website Schema
  const combinedSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : ''
    },
    ...(schemaOrgJSONLD || []),
  ]


  if (postSEO && postNode) 
  {
    title = `CancelledFlightRefund | ${postNode.title}`
    description =
      postNode.metaDescription === null
        ? postNode.body.childMarkdownRemark.excerpt
        : postNode.metaDescription.internal.content

    pageUrl = config.siteUrl + '/' + postPath + '/'
  }

  // Use Hero Image for OpenGraph
  if (postSEO && postNode.heroImage && postNode.heroImage.ogimg) {
    image = 'https:' + postNode.heroImage.ogimg.src
    imageWidth = postNode.heroImage.ogimg.width
    imageHeight = postNode.heroImage.ogimg.height
  }

  if (postSEO)
  {
    combinedSchema.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': config.siteUrl,
              name: config.siteTitle,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': pageUrl,
              name: title,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: pageUrl,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
          width: imageWidth,
          height: imageHeight,
        },
        author: {
          '@type': 'Person',
          name: config.author,
          url: config.authorUrl,
        },
        publisher: {
          '@type': 'Organization',
          name: config.publisher,
          url: config.siteUrl,
        },
        datePublished: postNode.publishDateISO,
        mainEntityOfPage: pageUrl,
      }
    )
  }

  return (
    <Location>
      {({ location }) => (
        <Helmet>
          {/* General tags */}
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="image" content={image} />

          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(combinedSchema)}
          </script>

          {/* OpenGraph tags */}
          <meta property="og:title" content={title} />
          {type
            ? <meta property="og:type" content={type} />
            : <meta property="og:type" content={'website'} />
          }
          <meta property="og:url" content={location.href} />
          <meta property='og:description' content={description} />
          <meta property="og:image" content={image} />
          <meta property='og:image:width' content={imageWidth} />
          <meta property="og:image:height" content={imageHeight} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />

        </Helmet>
      )}
    </Location>
  )
}
