import { useEnvironment } from './Environment'

export const useAppOnboardingUrl = (caseType: string) => {
  const { APP_CREATE_CASE_ROOT_URL, APP_ROOT_URL } = useEnvironment()

  return `${APP_ROOT_URL}/${APP_CREATE_CASE_ROOT_URL}/${caseType}`
}

export const useAppLoginUrl = () => {
  const { APP_ROOT_URL } = useEnvironment()

  return `${APP_ROOT_URL}`
}
